import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/bookmark',
    name: 'bookmark',
    component: () => import(/* webpackChunkName: "about" */ '../views/BookmarkView.vue')
  },
  {
    path: '/bookmarkEdit',
    name: 'bookmarkEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/BookmarkEditView.vue')
  },
  {
    path: '/background',
    name: 'background',
    component: () => import(/* webpackChunkName: "about" */ '../views/BackgroundView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChangePasswordView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const needLogin = !localStorage.getItem('Authorization')
  if (needLogin) {
    if (to.path === '/login') {
      next()
    } else {
      next('/login')
    }
  } else {
    if (to.path === '/login') {
      next('/')
    } else {
      next()
    }
  }
})

export default router
