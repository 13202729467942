import { createStore } from 'vuex'
import arrayToTree from 'array-to-tree'
import CONSTANT from '@/common/constant'
import api from '@/request/api'
import createPersistedstate from 'vuex-persistedstate'
import { ElMessage } from 'element-plus'
import { deepCopy } from '@/common/commonUtil'
export default createStore({
  plugins: [createPersistedstate({ storage: window.localStorage })],
  state: {
    bookmarkList: [],
    baseConfig: {}
  },
  getters: {
    [CONSTANT.GETTER_ALL_FOLDER_ID_LIST]: state => {
      return state.bookmarkList.filter(item => item.type === '1').map(item => item.id)
    },
    [CONSTANT.GETTER_CONFIG_BY_KEY]: state => key => {
      return (state.baseConfig)[key]
    },
    [CONSTANT.SELECT_BOOKMARK_BY_ID]: state => id => state.bookmarkList.find(item => item.id === id),
    [CONSTANT.SELECT_BOOKMARK_FOLDER_TREE]: state => disabledId => {
      const tempBookmarkList = deepCopy(state.bookmarkList.filter(item => item.type === '1')).sort((a, b) => a.sortIndex - b.sortIndex)
      if (disabledId) {
        for (let i = 0; i < tempBookmarkList.length; i++) {
          if (tempBookmarkList[i].id === disabledId) {
            tempBookmarkList[i].disabled = true
            break
          }
        }
      }
      const list = arrayToTree(tempBookmarkList, { parentProperty: 'pid', customID: 'id' })
      return [{ id: '-1', name: '根目录', type: '1', children: list }]
    },
    [CONSTANT.SELECT_BOOKMARK_TREE]: state => {
      const tempBookmarkList = deepCopy(state.bookmarkList).sort((a, b) => a.sortIndex - b.sortIndex)
      const list = arrayToTree(tempBookmarkList, { parentProperty: 'pid', customID: 'id' })
      return [{ id: '-1', name: '根目录', type: '1', children: list }]
    }
  },
  mutations: {
    [CONSTANT.DELETE_BOOKMARK_BY_ID_LIST]: (state, idList) => {
      idList.forEach(id => {
        state.bookmarkList.splice(state.bookmarkList.findIndex(item => item.id === id), 1)
      })
    },
    [CONSTANT.INIT]: (state, data) => {
      state.bookmarkList = data.bookmarkList
      state.baseConfig = data.baseConfig
    },
    [CONSTANT.INIT_BOOKMARK]: (state, data) => {
      state.bookmarkList = data
    },
    [CONSTANT.UPDATE_BOOKMARK]: (state, data) => {
      const bookmark = state.bookmarkList.find(item => item.id === data.id)
      for (const key in data) {
        bookmark[key] = data[key]
      }
    },
    [CONSTANT.INSERT_BOOKMARK]: (state, data) => {
      state.bookmarkList.push(data)
    },
    [CONSTANT.UPDATE_CONFIG]: (state, data) => {
      state.baseConfig[data.configKey] = data.configValue
    },
    [CONSTANT.BOOKMARK_SORT]: (state, list) => {
      for (let i = 0; i < list.length; i++) {
        state.bookmarkList.find(bookmark => bookmark.id === list[i].id).sortIndex = i
      }
    },
    [CONSTANT.BOOKMARK_SHORTCUTS_SORT]: (state, list) => {
      for (let i = 0; i < list.length; i++) {
        state.bookmarkList.find(bookmark => bookmark.id === list[i].id).shortcutsSortIndex = i
      }
    }
  },
  actions: {
    [CONSTANT.INIT]: (context, isTip) => {
      api.init(isTip).then(res => {
        context.commit(CONSTANT.INIT, res.data)
        if (isTip) {
          ElMessage({
            message: '数据刷新成功',
            type: 'success'
          })
        }
      })
    },
    [CONSTANT.UPDATE_OR_INSERT_BOOKMARK]: (context, data) => {
      if (data.id) {
        api.update(data).then(res => {
          context.commit(CONSTANT.UPDATE_BOOKMARK, res.data)
        })
      } else {
        api.insert(data).then(res => {
          context.commit(CONSTANT.INSERT_BOOKMARK, res.data)
        })
      }
    },
    [CONSTANT.DELETE_BOOKMARK_BY_ID_LIST]: (context, idList) => {
      context.commit(CONSTANT.DELETE_BOOKMARK_BY_ID_LIST, idList)
      api.delete(idList).then(() => {})
    },
    [CONSTANT.UPDATE_CONFIG]: (context, config) => {
      context.commit(CONSTANT.UPDATE_CONFIG, config)
      api.updateConfig(context.state.baseConfig).then(() => {})
    },
    [CONSTANT.BOOKMARK_SORT]: (context, list) => {
      context.commit(CONSTANT.BOOKMARK_SORT, list)
      api.bookmarkSort(list).then(() => {})
    },
    [CONSTANT.BOOKMARK_SHORTCUTS_SORT]: (context, list) => {
      context.commit(CONSTANT.BOOKMARK_SHORTCUTS_SORT, list)
      api.bookmarkShortcutsSort(list).then(() => {})
    },
    [CONSTANT.BOOKMARK_SORT_BY_TREE]: (context, data) => {
      api.bookmarkSortByTree(data).then(res => {
        context.commit(CONSTANT.INIT_BOOKMARK, res.data)
      })
    }
  },
  modules: {}
})
