import axios from 'axios'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import { logout } from '@/common/commonUtil'
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 1000 * 20,
  timeoutErrorMessage: '接口超时'
})

http.interceptors.request.use(config => {
  const Authorization = localStorage.getItem('Authorization')
  if (Authorization) {
    config.headers.Authorization = Authorization
  }
  return config
}, error => Promise.reject(error))

http.interceptors.response.use(response => {
  if (response.headers.authorization) {
    localStorage.setItem('Authorization', response.headers.authorization)
  } else {
    localStorage.removeItem('Authorization')
  }
  return response
}, error => Promise.reject(error))

export async function requestAsync (options) {
  const defaultOptions = {
    errorPrompt: true,
    successPrompt: false,
    loading: false
  }
  const currentOptions = Object.assign(defaultOptions, options)
  let loading
  try {
    if (options.loading === true) {
      loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    }
    const response = await http.request(options)
    if (options.loading === true) {
      loading.close()
    }
    const res = response.data
    if (res.code === 200) {
      if (currentOptions.successPrompt) {
        ElMessage({
          message: res.message,
          type: 'success'
        })
      }
      return res
    } else if (res.code === 401) {
      code401Handler(res.message)
    } else {
      if (currentOptions.errorPrompt === true) {
        codeOtherHandler(res.message)
      }
    }
    return Promise.reject(res.message)
  } catch (e) {
    if (options.loading === true) {
      loading.close()
    }
    if (currentOptions.errorPrompt === true) {
      errorHandler(e.message)
    }
    return Promise.reject(e.message)
  }
}

const code401Handler = msg => {
  logout()
}

const codeOtherHandler = msg => {
  ElMessage({
    showClose: false,
    message: msg,
    type: 'error'
  })
  // ElMessageBox.alert(msg, '异常', { autofocus: false, confirmButtonText: '确认' }).then(() => {}).catch(() => {})
  /* ElMessageBox.confirm(
    msg,
    '异常',
    {
      confirmButtonText: '确认',
      type: 'warning'
    }
  ).then(() => {}).catch(() => {}) */
}

const errorHandler = msg => {
  ElMessageBox.alert(msg, '异常', { autofocus: false, confirmButtonText: '确认' }).then(() => {}).catch(() => {})
  /* ElMessageBox.confirm(
    msg,
    '异常',
    {
      confirmButtonText: '确认',
      type: 'warning'
    }
  ).then(() => {}).catch(() => {}) */
}
