export default {
  SELECT_BOOKMARK_BY_ID: 'SELECT_BOOKMARK_BY_ID',
  SELECT_BOOKMARK_FOLDER_TREE: 'SELECT_BOOKMARK_FOLDER_TREE',
  DELETE_BOOKMARK_BY_ID_LIST: 'DELETE_BOOKMARK_BY_ID_LIST',
  INIT: 'INIT',
  INIT_BOOKMARK: 'INIT_BOOKMARK',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  UPDATE_OR_INSERT_BOOKMARK: 'UPDATE_OR_INSERT_BOOKMARK',
  UPDATE_BOOKMARK: 'UPDATE_BOOKMARK',
  INSERT_BOOKMARK: 'INSERT_BOOKMARK',
  GETTER_CONFIG_BY_KEY: 'GETTER_CONFIG_BY_KEY',
  GETTER_ALL_FOLDER_ID_LIST: 'GETTER_ALL_FOLDER_ID_LIST',
  BOOKMARK_SORT: 'BOOKMARK_SORT',
  BOOKMARK_SHORTCUTS_SORT: 'BOOKMARK_SHORTCUTS_SORT',
  SELECT_BOOKMARK_TREE: 'SELECT_BOOKMARK_TREE',
  BOOKMARK_SORT_BY_TREE: 'BOOKMARK_SORT_BY_TREE'
}
