import router from '@/router'
import { ElMessageBox } from 'element-plus'
import store from '@/store'
import CONSTANT from '@/common/constant'

export const logout = () => {
  sessionStorage.clear()
  localStorage.clear()
  router.push({ path: '/login' }).then(() => {})
}

export const isUrl = url => {
  const regExp = /^(?!mailto:)(?:(?:http|https|ftp):\/\/|\/\/)(?:\S+(?::\S*)?@)?(?:(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[0-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))|localhost)(?::\d{2,5})?(?:(\/|\?|#)[^\s]*)?$/gi
  return regExp.test(url)
}

export const deepCopy = data => JSON.parse(JSON.stringify(data))

const editBookmark = (id, bookmarkEditDialogBookmarkId, bookmarkEditDialogVisible, sourcePage) => {
  if (sourcePage === 'BookmarkDialog') {
    bookmarkEditDialogBookmarkId.value = id
    bookmarkEditDialogVisible.value = true
  } else if (sourcePage === 'BookmarkView') {
    router.push({ path: 'BookmarkEdit', query: { bookmarkId: id } }).then(() => {})
  }
}

const deleteBookmark = (id, bookmarkList) => {
  const bookmark = bookmarkList.value.find(item => item.id === id)
  const findAllNextBookmarkIdList = (id, idList) => {
    const nextList = bookmarkList.value.filter(item => item.pid === id)
    nextList.forEach(item => {
      idList.push(item.id)
      if (item.type === '1') {
        findAllNextBookmarkIdList(item.id, idList)
      }
    })
  }
  if (bookmark.type === '1') {
    ElMessageBox.confirm(
      '删除文件夹会删除其下所有内容，是否继续?',
      '提示',
      {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      const idList = [id]
      findAllNextBookmarkIdList(id, idList)
      store.dispatch(CONSTANT.DELETE_BOOKMARK_BY_ID_LIST, idList).then(() => {})
    }).catch(() => {})
  } else if (bookmark.type === '2') {
    ElMessageBox.confirm(
      '确认删除?',
      '提示',
      {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      store.dispatch(CONSTANT.DELETE_BOOKMARK_BY_ID_LIST, [id]).then(() => {})
    }).catch(() => {})
  }
}

export const handleBookmarkOperationCommand = (command, bookmarkEditDialogBookmarkId, bookmarkEditDialogVisible, bookmarkList, sourcePage) => {
  const commandList = command.split(':')
  const action = commandList[0]
  const id = commandList[1]
  switch (action) {
    case 'delete': deleteBookmark(id, bookmarkList); break
    case 'edit': editBookmark(id, bookmarkEditDialogBookmarkId, bookmarkEditDialogVisible, sourcePage); break
  }
}
