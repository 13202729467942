import { requestAsync } from '@/request/http'

export default {
  updateConfig: async data => await requestAsync({ method: 'POST', url: '/bookmark/updateConfig', data }),
  selectUrlInfo: async params => await requestAsync({ method: 'GET', url: '/bookmark/selectUrlInfo', params, errorParams: false }),
  update: async data => await requestAsync({ method: 'PUT', url: '/bookmark/update', data }),
  insert: async data => await requestAsync({ method: 'POST', url: '/bookmark/insert', data }),
  delete: async data => await requestAsync({ method: 'DELETE', url: '/bookmark/delete', data }),
  init: async isTip => await requestAsync({ method: 'GET', url: '/bookmark/init', loading: isTip }),
  login: async data => await requestAsync({ method: 'POST', url: '/sys-user/login', data }),
  bookmarkSort: async data => await requestAsync({ method: 'PUT', url: '/bookmark/bookmarkSort', data }),
  bookmarkShortcutsSort: async data => await requestAsync({ method: 'PUT', url: '/bookmark/bookmarkShortcutsSort', data }),
  bookmarkSortByTree: async data => await requestAsync({ method: 'PUT', url: '/bookmark/bookmarkSortByTree', data }),
  changePassword: async data => await requestAsync({ method: 'PUT', url: '/sys-user/changePassword', data, loading: true }),
  register: async data => await requestAsync({ method: 'POST', url: '/sys-user/register', data }),
  accessRegister: async () => await requestAsync({ method: 'GET', url: '/system-config/accessRegister' })
}
